/** @format */

.chat-icon:before {
  font-family: 'FontAwesome';
  font-weight: 100;
  font-size: 32px;
  content: '\f086';
  color: rgb(0, 27, 72);
}
.message_graph_title:before {
  font-family: 'FontAwesome';
  font-weight: 400;
  font-size: 16px;
  content: '\f075';
  color: rgb(255, 255, 255);
}
.answer_graph_title:before {
  font-family: 'FontAwesome';
  font-weight: 100;
  font-size: 16px;
  content: '\f086';
  color: rgb(255, 255, 255);
}
.answerOpened_graph_title:before {
  font-family: 'FontAwesome';
  font-weight: 100;
  font-size: 16px;
  content: '\f27b';
  color: rgb(255, 255, 255);
}
.entryPoint_graph_title:before {
  font-family: 'FontAwesome';
  font-weight: 100;
  font-size: 16px;
  content: '\f015';
  color: rgb(255, 255, 255);
}
.nextCustomAnswer:after {
  font-family: 'FontAwesome';
  font-weight: 100;
  font-size: 16px;
  content: '\f0e7';
  color: rgb(255, 255, 255);
  padding-right: 5px;
}
.defaultClass:after {
  font-family: 'FontAwesome';
  font-weight: 100;
  font-size: 16px;
  content: '\f069';
  color: rgb(255, 255, 255);
  padding-right: 5px;
}
.trigger_graph_title:before {
  font-family: 'FontAwesome';
  font-weight: 100;
  font-size: 16px;
  content: '\f0f3';
  color: rgb(255, 255, 255);
}
.AMS_graph_title:before {
  font-family: 'FontAwesome';
  font-weight: 100;
  font-size: 16px;
  content: '\f083';
  color: rgb(255, 255, 255);
}
.Anecdote_graph_title:before {
  font-family: 'FontAwesome';
  font-weight: 100;
  font-size: 16px;
  content: '\f129';
  color: rgb(255, 255, 255);
}
.AnecdotePOI_graph_title:before {
  font-family: 'FontAwesome';
  font-weight: 100;
  font-size: 16px;
  content: '\f129';
  color: rgb(255, 255, 255);
}
.Archive_graph_title:before {
  font-family: 'FontAwesome';
  font-weight: 100;
  font-size: 16px;
  content: '\f187';
  color: rgb(255, 255, 255);
}
.Checkpoint_graph_title:before {
  font-family: 'FontAwesome';
  font-weight: 100;
  font-size: 16px;
  content: '\f08d';
  color: rgb(255, 255, 255);
}
.Comment_graph_title:before {
  font-family: 'FontAwesome';
  font-weight: 100;
  font-size: 16px;
  content: '\f075';
  color: rgb(255, 255, 255);
}
.SoundEffect_graph_title:before {
  font-family: 'FontAwesome';
  font-weight: 100;
  font-size: 16px;
  content: '\f001';
  color: rgb(255, 255, 255);
}
.TeleportItem_graph_title:before {
  font-family: 'FontAwesome';
  font-weight: 100;
  font-size: 16px;
  content: '\f0f2';
  color: rgb(255, 255, 255);
}
.LayerItem_graph_title:before {
  font-family: 'FontAwesome';
  font-weight: 100;
  font-size: 16px;
  content: '\f083';
  color: rgb(255, 255, 255);
}
.Custom_graph_title:before {
  font-family: 'FontAwesome';
  font-weight: 100;
  font-size: 16px;
  content: '\f005';
  color: rgb(255, 255, 255);
}
.Document_graph_title:before {
  font-family: 'FontAwesome';
  font-weight: 100;
  font-size: 16px;
  content: '\f15b';
  color: rgb(255, 255, 255);
}
.DocumentPOI_graph_title:before {
  font-family: 'FontAwesome';
  font-weight: 100;
  font-size: 16px;
  content: '\f15b';
  color: rgb(255, 255, 255);
}
.Video_graph_title:before {
  font-family: 'FontAwesome';
  font-weight: 100;
  font-size: 16px;
  content: '\f008';
  color: rgb(255, 255, 255);
}
.Image360_graph_title:before {
  font-family: 'FontAwesome';
  font-weight: 100;
  font-size: 16px;
  content: '\f0ac';
  color: rgb(255, 255, 255);
}
.Discussion_graph_title:before {
  font-family: 'FontAwesome';
  font-weight: 100;
  font-size: 16px;
  content: '\f086';
  color: rgb(255, 255, 255);
}
.DiscussionPOI_graph_title:before {
  font-family: 'FontAwesome';
  font-weight: 100;
  font-size: 16px;
  content: '\f086';
  color: rgb(255, 255, 255);
}
.BackgroundMusic_graph_title:before {
  font-family: 'FontAwesome';
  font-weight: 100;
  font-size: 16px;
  content: '\f1c7';
  color: rgb(255, 255, 255);
}
.BackgroundMusicControls_graph_title:before {
  font-family: 'FontAwesome';
  font-weight: 100;
  font-size: 16px;
  content: '\f1de';
  color: rgb(255, 255, 255);
}
.SoundEffect_graph_title:before {
  font-family: 'FontAwesome';
  font-weight: 100;
  font-size: 16px;
  content: '\f001';
  color: rgb(255, 255, 255);
}
.Failure_graph_title:before {
  font-family: 'FontAwesome';
  font-weight: 100;
  font-size: 16px;
  content: '\f06a';
  color: rgb(255, 255, 255);
}
.GameArea_graph_title:before {
  font-family: 'FontAwesome';
  font-weight: 100;
  font-size: 16px;
  content: '\f279';
  color: rgb(255, 255, 255);
}
.Openable_graph_title:before {
  font-family: 'FontAwesome';
  font-weight: 100;
  font-size: 16px;
  content: '\f07c';
  color: rgb(255, 255, 255);
}
.POI_graph_title:before {
  font-family: 'FontAwesome';
  font-weight: 100;
  font-size: 16px;
  content: '\f041';
  color: rgb(255, 255, 255);
}
.Start_graph_title:before {
  font-family: 'FontAwesome';
  font-weight: 100;
  font-size: 16px;
  content: '\f015';
  color: rgb(255, 255, 255);
}
.SecondaryMission_graph_title:before {
  font-family: 'FontAwesome';
  font-weight: 100;
  font-size: 16px;
  content: '\f21b';
  color: rgb(255, 255, 255);
}
.Unlockable_graph_title:before {
  font-family: 'FontAwesome';
  font-weight: 100;
  font-size: 16px;
  content: '\f023';
  color: rgb(255, 255, 255);
}
.Success_graph_title:before {
  font-family: 'FontAwesome';
  font-weight: 100;
  font-size: 16px;
  content: '\f058';
  color: rgb(255, 255, 255);
}
.Timer_graph_title:before {
  font-family: 'FontAwesome';
  font-weight: 100;
  font-size: 16px;
  content: '\f252';
  color: rgb(255, 255, 255);
}
.TimeTravel_graph_title:before {
  font-family: 'FontAwesome';
  font-weight: 100;
  font-size: 16px;
  content: '\f073';
  color: rgb(255, 255, 255);
}
.Tool_graph_title:before {
  font-family: 'FontAwesome';
  font-weight: 100;
  font-size: 16px;
  content: '\f0ad';
  color: rgb(255, 255, 255);
}

.srd-default-node__title:before {
  padding-left: 5px;
}

.srd-default-node__name {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 200px;
}

.entryPointName {
  color: #001b48;
}

.padding-before::before {
  padding-right: 10px;
}
